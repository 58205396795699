var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[(_vm.selects.expertises.length || _vm.model.length)?[_c('v-card-title',{staticClass:"py-2 px-0 mt-4 d-flex justify-space-between"},[_c('div',[_c('span',[_vm._v(" Rapoarte disponibile ")]),_c('span',[_vm._v(" ("+_vm._s(_vm.selects.expertises.length)+") ")])]),_c('div',{staticClass:"d-flex align-center"},[(_vm.showExpertises)?_c('v-text-field',{staticClass:"py-0 mr-9",staticStyle:{"width":"300px"},attrs:{"label":"Cauta rapoarte","hide-details":"","dense":"","clearable":""},on:{"input":_vm.setSelectedExpertise},model:{value:(_vm.selectedExpertise),callback:function ($$v) {_vm.selectedExpertise=$$v},expression:"selectedExpertise"}}):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggleShowExpertise()}}},[(_vm.showExpertises)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1)]),_c('v-divider',{staticClass:"mx-2"}),_c('div',{staticClass:"full-width"},[_c('v-expand-transition',[(_vm.showExpertises)?_c('v-row',[_vm._l((_vm.selects.expertises),function(item,index){return _c('v-col',{key:index,staticClass:"px-1 py-1",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-card',{class:{
                exists: _vm.filteredItems.some(function (el) { return el.id === item.id; })
              },attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pt-1 pb-0 px-2 body-2 font-weight-bold"},[_c('span',[_vm._v(" "+_vm._s(item.number)+" ")]),_c('v-spacer')],1),_c('v-card-actions',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{attrs:{"depressed":"","outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.viewExpertise(item.id)}}},[_vm._v(" Vezi cererea ")]),_c('v-btn',{attrs:{"depressed":"","outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.openOrder(item.id)}}},[_vm._v(" Vezi raportul ")]),_c('v-btn',{attrs:{"color":"success","outlined":"","depressed":"","small":""},on:{"click":function($event){return _vm.addExpertise(item)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',[_vm._v(" Adauga raport ")])],1)],1)],1)],1)}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1)],2):_vm._e()],1)],1),_c('v-card-title',{staticClass:"py-2 px-2 "},[_c('span',[_vm._v(" Rapoarte selectate")]),_c('span',[_vm._v(" ("+_vm._s(_vm.model.length)+") ")])]),(_vm.model.length)?_c('v-card-text',{staticClass:"px-2"},_vm._l((_vm.model),function(report,reportIndex){return _c('v-card',{key:reportIndex,staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("orders.form.header.expertise_report", { number: report.exp_number }))+" ")]),_c('v-btn',{staticClass:"ml-4",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.viewExpertise(report.exp_id)}}},[_vm._v(" "+_vm._s(_vm.$t("orders.buttons.cert.view_expertise"))+" ")]),_c('v-spacer'),_c('v-btn',{directives:[{name:"confirm",rawName:"v-confirm",value:({
              title: _vm.$t('global_alert.deletion_title'),
              message: _vm.$t('global_alert.deletion_text'),
              callback: function () {
                _vm.deleteRow(reportIndex);
              }
            }),expression:"{\n              title: $t('global_alert.deletion_title'),\n              message: $t('global_alert.deletion_text'),\n              callback: () => {\n                deleteRow(reportIndex);\n              }\n            }"}],staticClass:"my-auto",staticStyle:{"justify-self":"end"},attrs:{"depressed":"","icon":"","color":"error"}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" mdi-delete ")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-row',[_vm._l((report.goods),function(item,index){return _c('v-col',{key:index + 1,staticClass:"col pa-0 pt-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"mx-3 my-auto font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("orders.tabs.exp.goods_counter") + " " + (index + 1))+" ")]),_c('v-card-text',{staticClass:"exp-panel-content"},[_c('v-row',{staticClass:"row mt-0"},[_c('v-col',{staticClass:"col pa-0 px-1",attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.selects.categories,"rules":[_vm.rules.required()],"placeholder":"Select*","label":((_vm.$t('orders.form.fields.exp.category')) + "*"),"outlined":"","dense":"","error-messages":_vm.errorMessages[("details.reports." + index + ".category_id")]},on:{"change":function($event){return _vm.loadChildrenList(item.category_id)}},model:{value:(item.category_id),callback:function ($$v) {_vm.$set(item, "category_id", $$v)},expression:"item.category_id"}})],1),_c('v-col',{staticClass:"col pa-0 px-1",attrs:{"cols":"12","sm":"6"}},[_c('v-select',{key:_vm.forceKey,attrs:{"rules":[_vm.rules.required()],"items":_vm.subcategories[item.category_id],"disabled":!item.category_id,"label":((_vm.$t('orders.form.fields.exp.subcategory')) + "*"),"placeholder":"Select*","outlined":"","dense":"","error-messages":_vm.errorMessages[
                          ("details.reports." + index + ".subcategory_id")
                        ]},model:{value:(item.subcategory_id),callback:function ($$v) {_vm.$set(item, "subcategory_id", $$v)},expression:"item.subcategory_id"}})],1),_vm._l((_vm.items),function(item,index){return _c('v-col',{key:index + 1},[_c('v-col',{staticClass:"col pa-0 px-1",attrs:{"cols":"12"}},[_c('nested-select',{attrs:{"clearable":"","outlined":"","attach":"","dense":"","field":"code","label":("" + (_vm.$t('orders.form.fields.exp.code'))),"search-function":_vm.searchByCode,"error-messages":_vm.errorMessages[("details.goods." + index + ".code")]},on:{"input":function($event){return _vm.setName($event, index)}},model:{value:(item.code),callback:function ($$v) {_vm.$set(item, "code", $$v)},expression:"item.code"}})],1),_c('v-col',{staticClass:"col pa-0 px-1",attrs:{"cols":"12"}},[_c('nested-select',{attrs:{"text-area":"","clearable":"","outlined":"","attach":"","dense":"","field":"name","label":("" + (_vm.$t('orders.form.fields.exp.stock'))),"search-function":_vm.searchByCode,"error-messages":_vm.errorMessages[("details.reports." + index + ".name")]},on:{"input":function($event){return _vm.setCode($event, index)}},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1)],1)})],2),_c('v-row',{staticClass:"row mt-0"},[_c('v-col',{staticClass:"col pa-0 px-1",attrs:{"cols":"6","sm":"6","md":"3"}},[_c('v-text-field',{staticClass:"pa-0 text-right",attrs:{"label":("" + (_vm.$t(
                          'orders.form.fields.document_types.cert.quantity_in_relation'
                        ))),"outlined":"","dense":"","error-messages":_vm.errorMessages[("details.reports." + index + ".quantity")]},on:{"input":function($event){return _vm.quantityDifference(reportIndex, index)}},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})],1),_c('v-col',{staticClass:"col pa-0 px-1",attrs:{"cols":"6","sm":"6","md":"3"}},[_c('v-text-field',{staticClass:"pa-0 text-right",attrs:{"label":("" + (_vm.$t(
                          'orders.form.fields.document_types.cert.quantity_in_certification'
                        ))),"outlined":"","dense":"","error-messages":_vm.errorMessages[("details.reports." + index + ".quantity")]},on:{"input":function($event){return _vm.quantityDifference(reportIndex, index)}},model:{value:(item.quantity_cert),callback:function ($$v) {_vm.$set(item, "quantity_cert", $$v)},expression:"item.quantity_cert"}})],1),_c('v-col',{staticClass:"col pa-0 px-1",attrs:{"cols":"6","sm":"6","md":"3"}},[_c('v-text-field',{staticClass:"pa-0 text-right",attrs:{"label":("" + (_vm.$t(
                          'orders.form.fields.document_types.cert.difference'
                        ))),"outlined":"","readonly":"","dense":"","error-messages":_vm.errorMessages[("details.reports." + index + ".quantity")]},model:{value:(item.difference),callback:function ($$v) {_vm.$set(item, "difference", $$v)},expression:"item.difference"}})],1),_c('v-col',{staticClass:"col pa-0 px-1",attrs:{"cols":"6","sm":"6","md":"3"}},[_c('v-select',{attrs:{"items":_vm.selects.units,"outlined":"","label":("" + (_vm.$t('orders.form.fields.exp.measure_unit'))),"error-messages":_vm.errorMessages[
                          ("details.reports." + index + ".measure_unit_id")
                        ],"dense":""},model:{value:(item.measure_unit_id),callback:function ($$v) {_vm.$set(item, "measure_unit_id", $$v)},expression:"item.measure_unit_id"}})],1)],1)],1)],1)}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1)],2)],1)],1)}),1):_vm._e()]:_c('v-alert',{staticClass:"text-center",attrs:{"type":"error","outlined":"","dense":""}},[_vm._v(" "+_vm._s(_vm.$t("orders.form.fields.exp.no_expertises"))+" ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }