
import Vue from "vue";
import rules from "@/services/helpers/validation-rules";

export default Vue.extend({
  name: "DocumentEdit",

  props: {
    orderId: {
      type: Number,
      required: true
    },
    value: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  data: () => ({
    rules,
    model: {} as any,
    logo: require("@/assets/images/cci/cert_logo.png") as any,
    bottomPicture: require("@/assets/images/cci/bottom-pic.png") as any,
    loading: false as any,
    hasScroll: [] as Array<string>
  }),

  computed: {
    orderNumber() {
      return this.value.order.number;
    }
  },

  async beforeMount(): Promise<void> {
    await this.loadData();
  },

  methods: {
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        this.model = await this.$API.orders().getDocument(this.orderId);

        if (!Object.keys(this.model).length) {
          this.model = await this.$API.orders().saveDocument(this.orderId, {});
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    async submit(): Promise<void> {
      this.loading = true;
      try {
        this.model = await this.$API
          .orders()
          .saveDocument(this.orderId, this.model);
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_save")
        );
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    scroll(event: any, key: string): void {
      const hasScroll = event.target.clientHeight !== event.target.scrollHeight;
      const item = (this.$refs as Vue)[key];

      if (hasScroll) {
        item.classList.add("error");
        this.hasScroll.push(key);
      } else {
        item.classList.remove("error");
        this.hasScroll = this.hasScroll.filter((item: string) => item !== key);
      }
    },
    async sentToBeneficiary(): Promise<void> {
      try {
        await this.$API.orders().changeOrderStatus(this.orderId, {
          alias: "check_beneficiary"
        });
        await this.$emit("update");
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
