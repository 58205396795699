
import Vue from "vue";
import rules from "@/services/helpers/validation-rules";

export default Vue.extend({
  name: "ExpertReport",

  props: {
    value: {
      type: Boolean,
      required: true
    },
    order: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  data: () => ({
    rules,
    model: {} as any,
    selects: {
      branches: [] as Array<SelectComponentInterface>,
      subdivisions: [] as Array<SelectComponentInterface>
    },
    dialog: false as boolean
  }),

  computed: {
    correctSubdivisions() {
      return this.selects.subdivisions.filter(item => {
        return item.value !== 40 && item.value !== 14;
      });
    }
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        //@ts-ignore
        this.dialog = this.value;
      }
    }
  },

  async mounted() {
    //@ts-ignore
    await this.loadBranches();
  },

  methods: {
    async loadBranches(): Promise<void> {
      try {
        const response = await this.$API.branches().getList({
          filter: {
            service_types: this.order.service_type_id
          }
        });

        this.selects.branches = response.items;

        if (
          !this.selects.branches.some(
            (item: any) => item.value === this.model.branch_id
          )
        ) {
          if (this.model.branch_id) {
            delete this.model.branch_id;
          }

          if (this.model.subdivision_id) {
            delete this.model.subdivision_id;
          }
        }

        if (this.selects.branches.length === 1) {
          const [branch] = this.selects.branches;
          this.model.branch_id = branch.value;
        }

        if (this.model.branch_id) {
          await this.loadSubdivisionsByBranch();
        } else {
          this.selects.subdivisions = [];
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    closeModal() {
      this.dialog = false;
      this.$emit("input", this.dialog);
    },
    async loadSubdivisionsByBranch(): Promise<any> {
      try {
        const response = await this.$API.subdivisions().getList({
          filter: {
            branches: this.model.branch_id,
            service_type: this.order.service_type_id
          }
        });

        this.selects.subdivisions = response.items;

        if (this.selects.subdivisions.length === 1) {
          const [subdivision] = this.selects.subdivisions;
          this.model.subdivision_id = subdivision.value;
        }

        if (
          this.model.subdivision_id &&
          !this.selects.subdivisions.some(
            (el: any) => el.value === this.model.subdivision_id
          )
        ) {
          delete this.model.subdivision_id;
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async createExpertize(): Promise<void> {
      try {
        await this.$API
          .orders()
          .createExpertize(Number(this.$route.params.id), {
            branch_id: this.model.branch_id,
            subdivision_id: this.model.subdivision_id
          });
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("orders.modals.alert.successful_create_expertize")
        );
        this.closeModal();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e[0]);
      }
    }
  }
});
